import './App.css';
import {useEffect, useState, useRef} from "react";
import {PrimeReactProvider} from 'primereact/api';
import "primereact/resources/themes/fluent-light/theme.css";
import "primeflex/primeflex.css";
import {FileUpload} from 'primereact/fileupload';
import {InputText} from 'primereact/inputtext';
import {Panel} from 'primereact/panel';
import {Button} from 'primereact/button';


function App() {
    const [message, setMessage] = useState('');
    const [sql, setSql] = useState('');
    const [value, setValue] = useState('');
    const [collapsed, setCollapsed] = useState(true);
    const fileUploadRef = useRef(null);
    useEffect(() => {
        // fetch from api/message
        fetch('/api/message')
            .then((response) => response.json())
            .then((data) => {
                setMessage(data.message);
            });
    }, []);

    const handleClear = () => {
        // Clear the InputText
        setValue('');

        // Clear the FileUpload selection
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };

    const handleFileSelect = (e) => {
        // Get the name of the first selected file
        const fileName = e.files[0].name;
        // Update the input with the file name
        setValue(fileName);
    };
    const handleOnUpload = (evt) => {
        setSql(evt.xhr.response)
        setValue('')
    };

    function handleOnBeforeUpload(e) {
        // Access the FormData object
        const formData = e.formData; // This is the FormData object

        // Append additional data before the file
        if (value) {
            formData.append('filename', value); // Use the state value or any other data you want to append
        }
    }

    return (
        <PrimeReactProvider>
            <header>Cyzag Process Diagram Upload Tool</header>
            <main>
                <div className="grid">
                    <div className="col-12">
                        <div>
                            <p>Upload SVG file to import graphics</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="flex flex-row gap-2 align-items-center">
                            <FileUpload mode="basic" ref={fileUploadRef} name="file" url="/api/uploadfileservice"
                                        maxFileSize={1000000}
                                        onUpload={handleOnUpload} onBeforeUpload={handleOnBeforeUpload}
                                        onSelect={handleFileSelect}/>
                            <div className="flex flex-column gap-2">
                                <label htmlFor="filename">Filename</label>
                                <InputText id="filename" aria-describedby="filename-help" value={value}
                                           onChange={(e) => setValue(e.target.value)}/>
                                <small id="filename-help">
                                    Enter a filename, or leave this blank to use the filename of the uploaded file.
                                </small>
                            </div>
                            <Button label="Reset Upload" onClick={handleClear}/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div>
                            <Panel header="Response Log" toggleable collapsed={collapsed}
                                   onToggle={event => setCollapsed(!collapsed)}>
                                <pre style={{overflowX: 'auto'}}>{sql}</pre>
                            </Panel>
                        </div>
                    </div>
                </div>
            </main>
            <footer><p>{message}</p></footer>
        </PrimeReactProvider>
    );
}

export default App;
